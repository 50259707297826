body{
  background: #131212
}

.App {
  text-align: center;
}

.max{
  margin: 100px 7% 0px;
   margin-top: 300px;

}

video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.box{
  background-color: darksalmon;
   padding: 20px;
    margin: 20px;
    flex: 1;
    min-width: 200px;
    height: 200px;
    box-shadow: 0px 0px 16px -1px #000000


}

h1{
  /* font-family: 'La Belle Aurore', cursive;
font-family: 'Libre Barcode 128 Text', cursive;
font-family: 'Major Mono Display', monospace;
font-family: 'Monofett', cursive;
font-family: 'Plaster', cursive; */
font-family: 'Playfair Display', serif;
/* font-family: 'Reenie Beanie', cursive;
font-family: 'Zilla Slab Highlight', cursive; */
}

.cols a{
color:peachpuff;
margin: 15px
}

a:link{
  text-decoration: none;
}

.about{
  font-size: 3em;
  text-align: left;
  font-family: 'Zilla Slab Highlight';
}

.placement{
  position: absolute;
    margin-top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.header{
  font-family: 'Playfair Display', serif;
  color:peachpuff;
  font-size: 2em;
    font-weight: bold;
  position: absolute;
    top: 50%;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(0, -2em);
}

.submenu{
  cursor: pointer;
}

.hovereffect{
  opacity:0;
  background:#000000d1;
  color:white;
  height: 100%;
  width: 100%;
  padding: 20px;
  margin-top: -20px;
  margin-left: -20px;
  text-align:left;
}

.hovereffectplacement{
  font-size:1.5em; 
  padding: 10px;
  padding-top:23%;
  border-bottom: 1px solid lavenderblush
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.box1{
background: crimson;
}

.box2{
background: darksalmon;
}

.box3{
background: indianred;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width: 760px) {
  .mobilemenu{
    display: unset
    }
    .fullmenu{
      display: none;
    }
    .box {
      padding: 60px;
      margin: unset;
      flex: 1 1;
      min-width: 200px;
      height: 200px;
      box-shadow: unset
  }
  .max{
    margin:0
  }

  .mobileeffect{
    background:"red"
  }

  .hovereffect{
    
    /* background: rgba(0, 0, 0, 0.82); */
    
    height: 100%;
    width: 100%;
    padding: 60px;
    text-align: left;
    position: relative;
    top: -40px;
    left: -40px;
  }

  .hovereffectplacement{
    font-size:1.5em; 
    padding: 10px;
    padding-top:12%;
    border-bottom: 1px solid lavenderblush
  }
}

@media only screen and (min-width: 760px) {
  .mobilemenu{
    display: none
    }
    .fullmenu{
      display: unset;
    }
}



